import { COMMON_STORE_KEYS } from "../../../utils/constants";
import { ListsService } from "../../openapi";
import { ChangesTracker } from "../../sync/core/ChangesTracker";
import { SyncCategoriesService } from "../../sync/services/SyncCategoriesService";
import { DbNames } from "../core/DbNames";
import { Stores } from "../core/Stores";
import getDb from "../core/getDb";
import { ListInternalModel, ListUpdateParams } from "../models/ListInternalModel";
import {
  ListItemInternalModel,
  ListItemUpdateParams,
} from "../models/ListItemInternalModel";

import { InternalStorageCategoriesService } from "./InternalStorageCategoriesService";
import { InternalStorageCommonService } from "./InternalStorageCommonService";

export class InternalStorageListsService {
  public static async getLists(dbName?: string): Promise<ListInternalModel[]> {
    try {
      const db = await getDb(dbName);
      return await db.getAll(Stores.LISTS_STORE);
    } catch (e) {
      console.log(
        `InternalStorageListsService.getLists --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async getListItems(
    listId: number,
    dbName?: string,
  ): Promise<ListItemInternalModel[]> {
    try {
      const db = await getDb(dbName);
      const allListItems: ListItemInternalModel[] = await db.getAll(
        Stores.LIST_ITEMS_STORE,
      );
      return allListItems
        .filter((listItem: ListItemInternalModel): boolean => {
          return listItem.localListId === listId;
        })
        .sort((a, b) => a.order - b.order);
    } catch (e) {
      console.log(
        `InternalStorageListsService.getListItems --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async getAllListItems(dbName?: string): Promise<ListItemInternalModel[]> {
    try {
      const db = await getDb(dbName);
      return await db.getAll(Stores.LIST_ITEMS_STORE);
    } catch (e) {
      console.log(
        `InternalStorageListsService.getAllListItems --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async createList(
    list: ListInternalModel,
    dbName?: string,
  ): Promise<ListInternalModel> {
    try {
      const db = await getDb(dbName);
      const tx = db.transaction(Stores.LISTS_STORE, "readwrite");
      await Promise.all([tx.store.add(list, list.localId), tx.done]);
      return await db.get(Stores.LISTS_STORE, list.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.createList --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async updateList(
    listUpdateParams: ListUpdateParams,
    dbName?: string,
  ): Promise<ListInternalModel> {
    try {
      const db = await getDb(dbName);
      const oldList: ListInternalModel = await db.get(
        Stores.LISTS_STORE,
        listUpdateParams.localId,
      );
      const tx = db.transaction(Stores.LISTS_STORE, "readwrite");
      await Promise.all([
        tx.store.put(
          { ...oldList, updated: new Date().toISOString(), ...listUpdateParams },
          listUpdateParams.localId,
        ),
        tx.done,
      ]);
      return await db.get(Stores.LISTS_STORE, listUpdateParams.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.updateList --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async deleteList(
    localListId: number,
    soft: boolean,
    dbName?: string,
  ): Promise<number> {
    try {
      const db = await getDb(dbName);
      const list: ListInternalModel = await db.get(Stores.LISTS_STORE, localListId);
      const linkedListItems: ListItemInternalModel[] = (
        await db.getAll(Stores.LIST_ITEMS_STORE)
      ).filter(
        (listItem: ListItemInternalModel): boolean =>
          listItem.localListId === localListId,
      );
      const date = new Date();
      let promises;
      if (soft) {
        promises = linkedListItems.map((listItem: ListItemInternalModel) =>
          db.put(
            Stores.LIST_ITEMS_STORE,
            { ...listItem, deleted: date.toISOString() },
            listItem.localId,
          ),
        );
        promises.push(
          db.put(
            Stores.LISTS_STORE,
            { ...list, deleted: date.toISOString() },
            list.localId,
          ),
        );
      } else {
        promises = linkedListItems.map((listItem: ListItemInternalModel) =>
          db.delete(Stores.LIST_ITEMS_STORE, listItem.localId),
        );
        promises.push(db.delete(Stores.LISTS_STORE, list.localId));
      }
      await Promise.all(promises);
      return localListId;
    } catch (e) {
      console.log(
        `InternalStorageListsService.deleteList --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async createListItem(
    listItem: ListItemInternalModel,
    dbName?: string,
  ): Promise<ListItemInternalModel> {
    try {
      const db = await getDb(dbName);
      const tx = db.transaction(Stores.LIST_ITEMS_STORE, "readwrite");
      await Promise.all([tx.store.add(listItem, listItem.localId), tx.done]);
      return await db.get(Stores.LIST_ITEMS_STORE, listItem.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.createListItem --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async updateListItem(
    listItemUpdateParams: ListItemUpdateParams,
    dbName?: string,
  ): Promise<ListItemInternalModel> {
    try {
      const db = await getDb(dbName);
      const oldListItem: ListItemInternalModel = await db.get(
        Stores.LIST_ITEMS_STORE,
        listItemUpdateParams.localId,
      );
      const tx = db.transaction(Stores.LIST_ITEMS_STORE, "readwrite");
      await Promise.all([
        tx.store.put(
          { ...oldListItem, updated: new Date().toISOString(), ...listItemUpdateParams },
          listItemUpdateParams.localId,
        ),
        tx.done,
      ]);
      return await db.get(Stores.LIST_ITEMS_STORE, listItemUpdateParams.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.updateListItem --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async deleteListItem(
    listItemLocalId: number,
    soft: boolean,
    dbName?: string,
  ): Promise<number> {
    try {
      const db = await getDb(dbName);
      const listItem: ListItemInternalModel = await db.get(
        Stores.LIST_ITEMS_STORE,
        listItemLocalId,
      );
      const tx = db.transaction(Stores.LIST_ITEMS_STORE, "readwrite");
      if (soft) {
        await Promise.all([
          tx.store.put(
            { ...listItem, deleted: new Date().toISOString() },
            listItemLocalId,
          ),
          tx.done,
        ]);
      } else {
        await Promise.all([tx.store.delete(listItemLocalId), tx.done]);
      }
      return listItemLocalId;
    } catch (e) {
      console.log(
        `InternalStorageListsService.deleteListItem --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async transferAnonymousDataToLoggedInUserAccount(): Promise<void> {
    try {
      const syncCategoriesService = new SyncCategoriesService(
        new ChangesTracker(2),
        () => null,
      );
      await syncCategoriesService.sync(true, true);
      const [
        userLists,
        userListItems,
        userCategories,
        anonymousLists,
        anonymousListItems,
      ] = await Promise.all([
        this.getLists(),
        this.getAllListItems(),
        InternalStorageCategoriesService.getCategories(),
        this.getLists(DbNames.DEFAULT_USER_NAME),
        this.getAllListItems(DbNames.DEFAULT_USER_NAME),
      ]);
      const listsForTransfer = anonymousLists.filter(
        (anonList) =>
          !anonList.deleted && !userLists.some((userList) => userList.id === anonList.id),
      );
      if (!listsForTransfer.length) {
        return;
      }
      const createUserLists = async () => {
        await Promise.all(listsForTransfer.map((list) => this.createList({ ...list })));
      };
      const createUserListItems = async () => {
        await Promise.all(
          anonymousListItems
            .filter(
              (anonListItem) =>
                !anonListItem.deleted &&
                !userListItems.some(
                  (userListItem) => userListItem.id === anonListItem.id,
                ),
            )
            .map((anonListItem) =>
              this.createListItem({
                ...anonListItem,
                localCategory:
                  userCategories.find((c) => c.id === anonListItem.localCategory?.id) ??
                  anonListItem.localCategory,
              }),
            ),
        );
      };
      const deleteAnonLists = async () => {
        await Promise.all(
          anonymousLists.map((list) =>
            this.deleteList(list.localId, false, DbNames.DEFAULT_USER_NAME),
          ),
        );
      };
      const deleteAnonListItems = async () => {
        await Promise.all(
          anonymousListItems.map((listItem) =>
            this.deleteListItem(listItem.localId, false, DbNames.DEFAULT_USER_NAME),
          ),
        );
      };
      const addSharedLists = async () => {
        await Promise.all(
          listsForTransfer.map((list) => {
            if (list.shareKey) {
              ListsService.postApiListsShared({ sharedListKey: list.shareKey });
            }
          }),
        );
      };
      await Promise.all([
        createUserLists(),
        createUserListItems(),
        deleteAnonLists(),
        deleteAnonListItems(),
        addSharedLists(),
      ]);
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
    } catch (e) {
      console.log(
        `InternalStorageListsService.transferAnonymousDataToLoggedInUserAccount -->
        ${e instanceof Error && e.message}`,
      );
    }
  }

  public static async deleteAllListsAndItems(dbName?: string): Promise<void> {
    const db = await getDb(dbName);
    await db.clear(Stores.LISTS_STORE);
    await db.clear(Stores.LIST_ITEMS_STORE);
  }
}
