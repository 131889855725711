import {
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  FilterOptionsState,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import "./ListItemAutocomplete.scss";
import React, { useEffect, useMemo, useState } from "react";

import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { MIN_LIST_ITEM_QUANTITY } from "../../utils/constants";
import normalizeText from "../../utils/normalizeText";
import { CustomSelectItem } from "../CustomSelectItem/CustomSelectItem";
import { QuantityInput } from "../QuantityInput/QuantityInput";

export type ListItemPromptOption = {
  name: string;
  buttonType?: "ADD" | "QUANTITY";
};

export interface ListItemAutocompleteProps {
  prompts?: ListItemPromptInternalModel[];
  onChange?: (listItemName: string, quantity: number) => void;
  disableAutofocus?: boolean;
}

const filter = createFilterOptions<ListItemPromptOption>();
const LIMIT_PROMPT_OPTIONS = 4;
const INITIAL_QUANTITY = MIN_LIST_ITEM_QUANTITY;

export const ListItemAutocomplete = (props: ListItemAutocompleteProps) => {
  const [newListItem, setNewListItem] = useState<ListItemPromptOption | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [quantityValue, setQuantityValue] = useState(INITIAL_QUANTITY);
  const [isQuantityChanged, setIsQuantityChanged] = useState(false);

  useEffect(() => {
    if (newListItem && props.onChange) {
      props.onChange(newListItem.name, +quantityValue);
      setNewListItem(null);
      setQuantityValue(INITIAL_QUANTITY);
      setIsQuantityChanged(false);
    }
  }, [newListItem]);

  useEffect(() => {
    if (!isQuantityChanged && quantityValue !== INITIAL_QUANTITY) {
      setIsQuantityChanged(true);
    }
  }, [quantityValue]);

  const listItemAutocompletePrompts: ListItemPromptOption[] = useMemo(() => {
    if (props.prompts) {
      const uniquePromptNames = new Set<string>();
      return props.prompts
        .filter((p) => {
          if (!uniquePromptNames.has(p.name)) {
            uniquePromptNames.add(p.name);
            return true;
          }
          return false;
        })
        .sort((a, b) => a.order - b.order)
        .map((p) => ({
          name: p.name,
        }));
    } else {
      return [];
    }
  }, [props.prompts]);

  const handleChangeAutocomplete = (
    _: React.SyntheticEvent,
    newValue: NonNullable<string | ListItemPromptOption> | null,
  ) => {
    if (typeof newValue === "string") {
      setNewListItem({
        name: newValue,
      });
    } else {
      setNewListItem(newValue);
    }
  };

  const handleFilterAutocompleteOptions = (
    options: ListItemPromptOption[],
    params: FilterOptionsState<ListItemPromptOption>,
  ) => {
    const inputValue = normalizeText(params.inputValue);
    const filtered = filter(options, params)
      .sort(
        (a, b) =>
          a.name.indexOf(inputValue) - b.name.indexOf(inputValue) ||
          a.name.length - b.name.length,
      )
      .slice(0, LIMIT_PROMPT_OPTIONS)
      .map((option) => ({
        ...option,
        name: option.name[0].toUpperCase() + option.name.slice(1),
      }));

    const isExisting = options.some(
      (option) => inputValue === normalizeText(option.name),
    );
    if (inputValue !== "" && !isExisting) {
      filtered.push({ name: params.inputValue, buttonType: "ADD" });
    }
    // filtered.push({ name: `Кол-во: ${inputValue}`, buttonType: "QUANTITY" });
    return filtered;
  };

  const handleGetOptionLabel = (option: string | ListItemPromptOption) => {
    if (typeof option === "string") {
      return option;
    }
    return option.name;
  };

  const handleRenderOption = (
    props: React.HTMLAttributes<HTMLElement>,
    option: ListItemPromptOption,
  ) => {
    if (option.buttonType === "ADD") {
      return (
        <ListItem
          key={option.name}
          {...props}
          className="list-item-autocomplete-option list-item-autocomplete-option_custom"
        >
          <CustomSelectItem name="Добавить:">
            <Typography className="list-item-autocomplete-option-name">
              «
              <span className="list-item-autocomplete-option-name-highlight">
                {option.name}
              </span>
              »
            </Typography>
          </CustomSelectItem>
        </ListItem>
      );
    } else if (option.buttonType === "QUANTITY") {
      return (
        <CustomSelectItem
          key={option.name}
          name="Кол-во:"
          onCheckClick={isQuantityChanged ? props.onClick : null}
        >
          <QuantityInput
            value={quantityValue}
            autofocus={false}
            onChange={setQuantityValue}
            minValue={MIN_LIST_ITEM_QUANTITY}
          />
        </CustomSelectItem>
      );
    }
    const inputValueNormalized = normalizeText(inputValue);
    const optionNameNormalized = normalizeText(option.name);
    const matchIndex = optionNameNormalized.indexOf(inputValueNormalized);
    return (
      <ListItem key={option.name} {...props} className="list-item-autocomplete-option">
        <Typography className="list-item-autocomplete-option-name">
          {matchIndex !== -1 ? (
            <>
              <span>{option.name.slice(0, matchIndex)}</span>
              <span className="list-item-autocomplete-option-name-highlight">
                {option.name.slice(matchIndex, matchIndex + inputValueNormalized.length)}
              </span>
              <span>{option.name.slice(matchIndex + inputValueNormalized.length)}</span>
            </>
          ) : (
            option.name
          )}
        </Typography>
      </ListItem>
    );
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      autoFocus={!props.disableAutofocus}
      placeholder="Название позиции"
      variant="standard"
      InputProps={{
        ...params.InputProps,
        endAdornment: null,
      }}
    />
  );

  return (
    <Autocomplete
      value={newListItem}
      open={inputValue.length > 1}
      onOpen={() => setQuantityValue(INITIAL_QUANTITY)}
      options={listItemAutocompletePrompts}
      className="list-item-autocomplete"
      autoFocus={!props.disableAutofocus}
      clearOnBlur
      handleHomeEndKeys
      id="create-list-item-autocomplete"
      freeSolo
      onChange={handleChangeAutocomplete}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue.slice(0, 200))}
      inputValue={inputValue}
      filterOptions={handleFilterAutocompleteOptions}
      getOptionLabel={handleGetOptionLabel}
      renderOption={handleRenderOption}
      renderInput={handleRenderInput}
    />
  );
};
