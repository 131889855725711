/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddListItemRequest } from '../models/AddListItemRequest';
import type { GetListItemsFromLlmRequest } from '../models/GetListItemsFromLlmRequest';
import type { ListItemDto } from '../models/ListItemDto';
import type { UpdateListItemRequest } from '../models/UpdateListItemRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ListItemsService {

    /**
     * @param listId 
     * @returns ListItemDto Success
     * @throws ApiError
     */
    public static getApiListsItems(
listId: number,
): CancelablePromise<Array<ListItemDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lists/{listId}/items',
            path: {
                'listId': listId,
            },
        });
    }

    /**
     * @param listId 
     * @param requestBody 
     * @returns ListItemDto Success
     * @throws ApiError
     */
    public static postApiListsItem(
listId: number,
requestBody?: AddListItemRequest,
): CancelablePromise<ListItemDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lists/{listId}/item',
            path: {
                'listId': listId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param shareKey 
     * @param requestBody 
     * @returns ListItemDto Success
     * @throws ApiError
     */
    public static postApiListsShareKeyItem(
shareKey: string,
requestBody?: AddListItemRequest,
): CancelablePromise<ListItemDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lists/share-key/{shareKey}/item',
            path: {
                'shareKey': shareKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param listId 
     * @param listItemId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiListsItem(
listId: number,
listItemId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/lists/{listId}/item/{listItemId}',
            path: {
                'listId': listId,
                'listItemId': listItemId,
            },
        });
    }

    /**
     * @param listId 
     * @param listItemId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiListsItem(
listId: number,
listItemId: number,
requestBody?: UpdateListItemRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/lists/{listId}/item/{listItemId}',
            path: {
                'listId': listId,
                'listItemId': listItemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param shareKey 
     * @param listItemId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiListsShareKeyItem(
shareKey: string,
listItemId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/lists/share-key/{shareKey}/item/{listItemId}',
            path: {
                'shareKey': shareKey,
                'listItemId': listItemId,
            },
        });
    }

    /**
     * @param shareKey 
     * @param listItemId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiListsShareKeyItem(
shareKey: string,
listItemId: number,
requestBody?: UpdateListItemRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/lists/share-key/{shareKey}/item/{listItemId}',
            path: {
                'shareKey': shareKey,
                'listItemId': listItemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiListsItemsLlmGenerate(
requestBody?: GetListItemsFromLlmRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lists/items/llm-generate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
