import { Capacitor } from "@capacitor/core";
import { SpeechRecognition } from "@capacitor-community/speech-recognition";
import { useState, useEffect } from "react";

const checkIsSupported = async () => {
  if (Capacitor.isNativePlatform()) {
    const { available } = await SpeechRecognition.available();
    return available;
  }
  return false;
};

const checkAndRequestPermissions = async () => {
  const permissionStatus = await SpeechRecognition.checkPermissions();
  if (permissionStatus.speechRecognition === "granted") {
    return true;
  }
  const requestStatus = await SpeechRecognition.requestPermissions();
  return requestStatus.speechRecognition === "granted";
};

export const useNativeSpeechRecognition = (): {
  transcript: string;
  resetTranscript: () => void;
  isListening: boolean;
  startListening: (options?: { language?: string }) => Promise<void>;
  stopListening: () => Promise<void>;
  isAvailable: boolean;
} => {
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    checkIsSupported().then(async (isSupported) => {
      if (isSupported) {
        setIsAvailable(true);
      }
    });
    SpeechRecognition.addListener("partialResults", ({ matches }) => {
      if (matches.length) {
        setTranscript(matches[0]);
      }
    }).catch(console.log);
    SpeechRecognition.addListener("listeningState", ({ status }) => {
      if (status === "stopped") {
        setTimeout(() => {
          setIsListening(false);
        }, 500);
      }
    }).catch(console.log);
    return () => {
      SpeechRecognition.removeAllListeners().catch(console.log);
    };
  }, []);

  const startListening = async (options?: { language?: string }) => {
    if (!isAvailable) {
      return;
    }
    const hasPermission = await checkAndRequestPermissions();
    if (!hasPermission) {
      return;
    }
    try {
      setTranscript("");
      setIsListening(true);
      await SpeechRecognition.start({
        language: options?.language || "ru-RU",
        partialResults: true,
        popup: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const stopListening = async () => {
    setIsListening(false);
    await SpeechRecognition.stop();
  };

  const resetTranscript = () => {
    setTranscript("");
  };

  return {
    transcript,
    resetTranscript,
    isListening,
    startListening,
    stopListening,
    isAvailable,
  };
};
