import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Button, Box, Typography, IconButton, Dialog } from "@mui/material";
import "./ListItemButton.scss";
import React, { useEffect, useState } from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { MIN_LIST_ITEM_QUANTITY } from "../../utils/constants";
import { AddIcon } from "../AddIcon/AddIcon";
import { CategoryBadge } from "../CategoryBadge/CategoryBadge";
import { CustomSelectItem } from "../CustomSelectItem/CustomSelectItem";
import { ListItemAutocomplete } from "../ListItemAutocomplete/ListItemAutocomplete";
import { QuantityInput } from "../QuantityInput/QuantityInput";

export interface ListItemButtonProps {
  name?: string;
  localId?: number;
  category?: ListItemCategoryInternalModel | null;
  prompts?: ListItemPromptInternalModel[];
  isChecked: boolean;
  quantity?: number;
  onQuantityChange?: (localId: number, quantity: number) => void;
  onClick?: (listItemId: number) => void;
  onEditClick?: (listItemId: number) => void;
  onSaveNewListItem?: (listItemName: string, quantity: number) => void;
  onCancelListItemCreation?: () => void;
}

export const ListItemButton = (props: ListItemButtonProps) => {
  const [isQuantityDialogOpen, setIsQuantityDialogOpen] = useState(false);
  const [quantityInput, setQuantityInput] = useState<number>(MIN_LIST_ITEM_QUANTITY);

  useEffect(() => {
    setQuantityInput(props.quantity ?? MIN_LIST_ITEM_QUANTITY);
  }, [props.quantity]);

  const handleClick = () => {
    if (props.onClick && props.localId) {
      props.onClick(props.localId);
    }
  };

  const handleEditCategoryClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (props.onEditClick && props.localId) {
      props.onEditClick(props.localId);
    }
  };

  const handleEditQuantityClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsQuantityDialogOpen(true);
  };

  const handleQuantityMenuClose = () => {
    setIsQuantityDialogOpen(false);
    if (quantityInput && props.onQuantityChange && props.localId) {
      props.onQuantityChange(props.localId, +quantityInput);
    }
  };

  return (
    <Box className="list-item-button">
      <Button color="secondary" className="list-item-button-body" onClick={handleClick}>
        {props.isChecked ? (
          <CheckCircleRoundedIcon
            color="primary"
            className="list-item-button-body-checked-icon"
          />
        ) : (
          <Box className="list-item-button-body-unchecked-icon" />
        )}
        {props.onSaveNewListItem ? (
          <ListItemAutocomplete
            prompts={props.prompts}
            onChange={props.onSaveNewListItem}
          />
        ) : (
          <Typography className="list-item-button-body-name">{props.name}</Typography>
        )}
      </Button>
      <Box className="list-item-button-controls">
        {/*{props.quantity && (*/}
        {/*  <Box*/}
        {/*    className="list-item-button-controls-quantity"*/}
        {/*    onClick={handleEditQuantityClick}*/}
        {/*  >*/}
        {/*    {props.quantity}*/}
        {/*  </Box>*/}
        {/*)}*/}
        {props.onEditClick && (
          <IconButton
            className="list-item-button-controls-button"
            onClick={handleEditCategoryClick}
          >
            {props.category ? (
              <CategoryBadge color={props.category.colorDark} />
            ) : (
              <AddIcon />
            )}
          </IconButton>
        )}
        {props.onCancelListItemCreation && (
          <IconButton
            className="list-item-button-controls-button"
            onClick={props.onCancelListItemCreation}
          >
            <CancelOutlinedIcon color="error" />
          </IconButton>
        )}
      </Box>
      {isQuantityDialogOpen && (
        <Dialog open={isQuantityDialogOpen} onClose={handleQuantityMenuClose}>
          <CustomSelectItem name="Кол-во:" onCheckClick={handleQuantityMenuClose}>
            <QuantityInput
              value={quantityInput}
              onChange={setQuantityInput}
              onEnter={handleQuantityMenuClose}
              autofocus={true}
              minValue={MIN_LIST_ITEM_QUANTITY}
            />
          </CustomSelectItem>
        </Dialog>
      )}
    </Box>
  );
};
